.circular-button {
  background-color: #4caf50; /* Green background */
  border: none;
  color: white;
  padding: 10px 10px; /* Adjust padding as needed */
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 50%; /* Circular shape */
  height: 40px; /* Match the height of the Help button */
  width: 40px; /* Make it a circle */
}

.circular-button img {
  height: 100%; /* Scale the icon to fit the button */
  width: auto;
  display: block; /* Remove any extra space below the image */
}
