/* App.css */

.map-container {
  flex: 3;
  border: 2px solid #2196f3;
  border-radius: 8px;
  overflow: hidden;
}

.chatbot-container {
  flex: 1;
  background-color: #ffc107;
  border: 2px solid #ffa000;
  border-radius: 8px;
  color: #212121;
  overflow-y: auto; /* Makes it scrollable */
}
/* Align text in messages to the left */
.cs-message__content {
  text-align: left !important;
}
.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  height: 100vh;
  margin: 0;
  padding: 20px;
  box-sizing: border-box;
  background-color: #fafafa;
}

.App-header {
  background-color: #424242;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  color: white;
  border-radius: 8px;
  margin-bottom: 1rem;
}

.logo {
  color: #bdbdbd;
  text-align: center;
}

.nav-buttons {
  display: flex;
}

.nav-buttons button,
.nav-buttons a {
  background-color: #f44336;
  border: none;
  padding: 10px 15px;
  border-radius: 8px;
  cursor: pointer;
  color: white;
  margin-left: 10px;
  text-decoration: none;
}

.main-body {
  display: flex;
  flex-grow: 1;
  gap: 20px;
  margin-bottom: 20px;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: auto;
  max-width: 500px; /* Adjust width as needed */
  display: flex;
  flex-direction: column;
  align-items: center;
}

.close-button {
  background-color: #f44336;
  color: white;
  padding: 10px 20px;
  margin-top: 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}
/* Responsive styles */
@media (max-width: 768px) {
  .main-body {
    flex-direction: column;
    padding: 10px; /* Reduced padding on mobile */
  }

  .map-container {
    height: 60%; /* 60% of remaining space */
    order: 2; /* Ensures map is below chatbot on mobile */
  }

  .chatbot-container {
    height: 40%; /* 40% of remaining space */
    order: 1; /* Ensures chatbot is above map on mobile */
    overflow-y: auto; /* Allows scrolling within chatbot container */
  }
}
